const { getDecodedInstance } = require('./getDecodedInstance');
const get_ = require('lodash/get');
const uniq_ = require('lodash/uniq');
const flatten_ = require('lodash/flatten');
const { ModuleList } = require('@wix/auto-frontend-module-registry');

const PREVIEW_BASE_URL = '_api/cloud-user-code-dev-analyzer/v1/apps';
const LIVE_BASE_URL = '_api/cloud-user-code-analyzer/v1/apps';
const DEFAULT_BASE_URL = 'https://www.wix.com';
const ENDPOINT_SUFFIX = 'pages-imported-namespaces';

const evaluateUrl = (baseUrl, viewMode, gridAppId, metaSiteId, pageIds) => {
  return `${baseUrl}/${
    viewMode === 'Site' ? LIVE_BASE_URL : PREVIEW_BASE_URL
  }/${gridAppId}/${ENDPOINT_SUFFIX}?${new URLSearchParams({
    metaSiteId,
    gridAppId,
    pageIds,
  })}`;
};

const resolveBaseUrl = wixCodeApi => {
  return get_(wixCodeApi, ['location', 'baseUrl'], DEFAULT_BASE_URL);
};

const resolveImportedNamespaceIfNeeded = async (
  userConsole,
  shouldUseAnalyzedImportedNamespace,
  gridAppId,
  pageIds,
  instance,
  viewMode,
  baseUrl,
  logger,
) => {
  if (!shouldUseAnalyzedImportedNamespace) {
    return [];
  }

  const namespaces = await Promise.race([
    resolveImportedNamespaceForPages(
      userConsole,
      gridAppId,
      uniq_(pageIds),
      instance,
      viewMode,
      baseUrl,
      logger,
    ),
    importedNamespaceTimeoutPromise(logger),
  ]);

  userConsole.debug('Resolved namespaces', namespaces);

  return namespaces;
};

const importedNamespaceTimeoutPromise = async logger => {
  return new Promise(res => {
    setTimeout(() => {
      logger.error('Resolving imported namespaces is hung timeout was reached');
      res(DEFAULT_NAMESPACE_LIST);
    }, IMPORTED_NAMESPACE_TIMEOUT_IN_MILLIS);
  });
};

const resolveImportedNamespaceForPages = async (
  userConsole,
  gridAppId,
  pageIds,
  instance,
  viewMode,
  baseUrl,
  logger,
) => {
  try {
    const { metaSiteId } = getDecodedInstance(instance);
    const url = evaluateUrl(baseUrl, viewMode, gridAppId, metaSiteId, pageIds);
    const req = new Request(url, {
      method: 'GET',
      headers: { Authorization: instance },
      params: {
        metaSiteId,
        gridAppId,
        pageIds,
      },
    });
    const res = await fetch(req);
    if (res.status !== 200) {
      logger.error('Unable to resolve imported namespaces', res.error);

      return DEFAULT_NAMESPACE_LIST;
    }
    const data = await res.json();

    const importedNamespaces = parseImportedNamespaceResult(data);

    userConsole.debug(
      'Imported namespaces response',
      importedNamespaces,
      pageIds,
      metaSiteId,
    );

    return importedNamespaces;
  } catch (e) {
    logger.error('Unable to resolve imported namespaces', e.message);
    return DEFAULT_NAMESPACE_LIST;
  }
};

function parseImportedNamespaceResult(pagesImportedNamespacesResult) {
  const { pagesImportedNamespaces } = pagesImportedNamespacesResult;
  const importedNamespacePerPage = pagesImportedNamespaces.map(
    ({ importedNamespaces }) =>
      importedNamespaces ? importedNamespaces.map(({ name }) => name) : [],
  );

  return uniq_(flatten_(importedNamespacePerPage));
}

const DEFAULT_NAMESPACE_LIST = ModuleList;

const IMPORTED_NAMESPACE_TIMEOUT_IN_MILLIS = 500;

module.exports.resolveImportedNamespaceIfNeeded = resolveImportedNamespaceIfNeeded;
module.exports.evaluateUrl = evaluateUrl;
module.exports.resolveBaseUrl = resolveBaseUrl;
module.exports.PREVIEW_BASE_URL = PREVIEW_BASE_URL;
module.exports.LIVE_BASE_URL = LIVE_BASE_URL;
