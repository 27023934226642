const ANALYZE_IMPORTED_NAMESPACES_QUERY_PARAM = 'analyze-imported-namespaces';

const userCodeMapToUrls = userCodeMap =>
  userCodeMap.map(({ url }) => new URL(url));

const userCodeMapToSearchParamsMap = userCodeMap => {
  const allSearchParams = userCodeMapToUrls(userCodeMap)
    .map(url => Array.from(url.searchParams.entries()))
    .flat();

  return new Map(allSearchParams);
};

const isAnalyzeImportedNamespaceParamTrue = searchParamsMap => {
  return (
    searchParamsMap.get(ANALYZE_IMPORTED_NAMESPACES_QUERY_PARAM) === 'true'
  );
};

module.exports = {
  userCodeMapToSearchParamsMap,
  isAnalyzeImportedNamespaceParamTrue,
};
